$eigengrau: #16161d;
$secondary_background: #222229;
$body_fonts: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
$title_fonts: Garamond, serif;
$text_colour: #fff;
$title_size: calc(18px + 3vh);
$subtitle_size: calc(15px + 2vh);
$body_size: calc(10px + 1vh);
$content_link_size: calc(5px + 2vh);

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  color: $text_colour;
  font-family: $body_fonts;
  font-size: $body_size;
}

a {
  color: #4545ff;
}

body {
  background-color: $eigengrau;
}

.mainDiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3%;
  grid-template-rows: auto auto 1fr;
}

.mainDiv::after {
  content: "";
  grid-column: 1;
  grid-row: 3;
}

.section:nth-child(2) {
  grid-column: 2;
  grid-row: 1 / -1;
}

.section {
  margin: 5%;
  min-width: 360px;
  display: flex;
  flex-direction: column;

  .fieldset {
    border: 4px solid #fff;
    box-shadow: 20px 20px 0px 10px rgba(0, 0, 0, 1);

    .legend {
      margin: 0px 5% 0px 5%;
      font-weight: 500;

      .title {
        font-family: $title_fonts;
        font-size: $title_size;
        margin: 0px 15px;
      }

      .subtitle {
        font-family: $title_fonts;
        font-size: $subtitle_size;
        margin: 0px 10px;
      }
    }

    .contentList {
      background-color: $secondary_background;
      margin: 4%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 20px;

      .listItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        list-style-type: none;
        cursor: pointer;
        background-color: transparent;
        text-align: left;
        font-size: $content_link_size;
        padding-left: 50px;
      }
      .listItem:hover {
        border: 2px solid #fff;
        background-color: rgba(0, 0, 0, 0.2);
      }

      &.primary {
        .listItem {
          //   border: none;
          border-radius: 20px;
          width: 100%;
          height: 50px;
        }
      }

      &.secondary {
        .listItem {
          margin: 2%;
          border: 2px solid #fff;
          width: 95%;
          padding: 5px 5px 5px 25px;
        }
      }
    }

    .contentBlock {
      background-color: $secondary_background;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 4%;
      gap: 20px;
      padding: 20px;

      // .contentSubHeadings {
      // }
    }
  }

  .img {
    display: block;
    border: 2px solid #000;
    margin: 5px;

    &#nea_car_demo {
      width: 30%;
      min-width: 225px;
      //   margin: auto;
    }

    &#nea_login_demo {
      width: 20%;
      min-width: 125px;
      //   float: right;
    }

    &#nea_extract_demo {
      max-width: 95%;
      //   margin: auto;
    }
  }
}

@media (max-width: 900px) {
  .mainDiv {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin-bottom: 100px;
    gap: 0px;

    .section {
      grid-column: auto;
      grid-row: auto;
    }
  }
}
