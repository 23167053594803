* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  color: #fff;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: calc(10px + 1vh);
}

a {
  color: #4545ff;
}

body {
  background-color: #16161d;
}

.mainDiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3%;
  grid-template-rows: auto auto 1fr;
}

.mainDiv::after {
  content: "";
  grid-column: 1;
  grid-row: 3;
}

.section:nth-child(2) {
  grid-column: 2;
  grid-row: 1/-1;
}

.section {
  margin: 5%;
  min-width: 360px;
  display: flex;
  flex-direction: column;
}
.section .fieldset {
  border: 4px solid #fff;
  box-shadow: 20px 20px 0px 10px rgb(0, 0, 0);
}
.section .fieldset .legend {
  margin: 0px 5% 0px 5%;
  font-weight: 500;
}
.section .fieldset .legend .title {
  font-family: Garamond, serif;
  font-size: calc(18px + 3vh);
  margin: 0px 15px;
}
.section .fieldset .legend .subtitle {
  font-family: Garamond, serif;
  font-size: calc(15px + 2vh);
  margin: 0px 10px;
}
.section .fieldset .contentList {
  background-color: #222229;
  margin: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
.section .fieldset .contentList .listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  cursor: pointer;
  background-color: transparent;
  text-align: left;
  font-size: calc(5px + 2vh);
  padding-left: 50px;
}
.section .fieldset .contentList .listItem:hover {
  border: 2px solid #fff;
  background-color: rgba(0, 0, 0, 0.2);
}
.section .fieldset .contentList.primary .listItem {
  border-radius: 20px;
  width: 100%;
  height: 50px;
}
.section .fieldset .contentList.secondary .listItem {
  margin: 2%;
  border: 2px solid #fff;
  width: 95%;
  padding: 5px 5px 5px 25px;
}
.section .fieldset .contentBlock {
  background-color: #222229;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4%;
  gap: 20px;
  padding: 20px;
}
.section .img {
  display: block;
  border: 2px solid #000;
  margin: 5px;
}
.section .img#nea_car_demo {
  width: 30%;
  min-width: 225px;
}
.section .img#nea_login_demo {
  width: 20%;
  min-width: 125px;
}
.section .img#nea_extract_demo {
  max-width: 95%;
}

@media (max-width: 900px) {
  .mainDiv {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin-bottom: 100px;
    gap: 0px;
  }
  .mainDiv .section {
    grid-column: auto;
    grid-row: auto;
  }
}/*# sourceMappingURL=stylesheet.css.map */